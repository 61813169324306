<template>
  <div class="fp-homepage">
    <tz-banner
      title="Free People Search Service"
      description="Find Email Addresses, Phone Numbers, Social Media Data, Contact Information and More!"
      :has-overlay="false"
    >
      <div class="fp-homepage__banner-search-tabs">
        <a
          :href="tab.link"
          class="fp-homepage__banner-search-tab"
          v-for="tab in searchTabs"
          :class="{
            'fp-homepage__banner-search-tab--active': tab.name === 'People'
          }"
          v-html="`${tab.name} <span>Search</span>`"
        />
      </div>
      <tz-search
        :people-link="peopleURL"
        :prefill-fields="$parent.$attrs['prefill-fields']"
        :fallback-fields="fallbackFields"
        :search-icon="longArrowImg"
        :text-search-icon-size="textSearchIconSize"
        :people-invalid-regex="getRegex({ regexType: 'peopleInvalidRegex' })"
        @tz-search-override-redirect="handleSearch"
        :no-query-string="true"
      />
      <div class="fp-homepage__banner-secured">
        <img :src="securityBadges" alt="" width="220" height="30" />
        <p
          class="fp-homepage__banner-secured-text"
          v-text="`This connection is secured`"
        />
      </div>
    </tz-banner>
    <section class="fp-homepage__section fp-homepage__section--seen-on">
      <img :src="asSeenOnImg" alt="Company logos" width="350" height="30" />
    </section>
    <section class="fp-homepage__section">
      <div class="fp-homepage__row">
        <img
          :src="AccuratePeopleLookImg"
          alt="Accurate People Lookup Online"
          class="fp-homepage__section-image"
        />
        <div>
          <h2 v-text="`Accurate People Lookup Online`" />
          <p
            v-text="
              `Need to find someone and don’t know where to start? That’s okay! Free People Search makes it fast, easy,
            and effective to perform an advanced people search today. People lookup services can be flaky and seldom do
            they truly deliver the goods. But we can help you find anyone with our free people search tool.`
            "
          />
          <p
            v-text="
              `Do you need to perform a people search to make contact, or do you
            need to obtain a criminal background check? We can do it all at
            Free People Search, where your people lookup troubles will go away
            right when you use our website. We make the process easy and
            informative.`
            "
          />
          <p
            v-text="
              `Our people search service utilizes public information like criminal
            records, marriage records, civil records, bankruptcies, liens,
            lawsuits, etc. All of this information powers our free people search
            service so that our clients can find anyone quick and easy.`
            "
          />
        </div>
      </div>
    </section>
    <section class="fp-homepage__section fp-homepage__section--color">
      <ul class="fp-homepage__list fp-homepage__list--decisions">
        <li v-text="`The best tool to Search People`" />
        <li v-text="`100% Confidential Searches`" />
        <li v-text="`Peace of mind, Always!`" />
        <li v-text="`Easy to Use and Understand`" />
      </ul>
      <a
        href="#"
        v-text="`Make A Search`"
        class="fp-homepage__button"
        @click.prevent="scrollToTop"
      />
    </section>
    <section class="fp-homepage__section">
      <div class="fp-homepage__container">
        <h2 v-text="`Frequently Asked Questions`" />
        <ul class="fp-homepage__list fp-homepage__list--faq">
          <li>
            <h3
              v-text="`Why run a People Lookup Search with Free People Search?`"
            />
            <p
              v-text="
                `We provide instantaneous access to abundant contact information, background checks, public records, and more, all in one, easy-to-read printout report. Find people, contacts, and learn more about criminal records so that you can connect with others with a new peace of mind while protecting your own personal safety.`
              "
            />
          </li>
          <li>
            <h3 v-text="`Is there a limit on how many people I can search?`" />
            <p
              v-text="
                `With FreePeopleSearch, you can get unlimited searches & reports! Our people search services are never meant to be used to stalk or harass individuals, or to steal another person’s identity. We are a safe and easy people search solution when other background check and people lookup services turn up dry.`
              "
            />
          </li>
          <li>
            <h3
              v-text="
                `What type of information will I find with Free People Search?`
              "
            />
            <p
              v-text="
                `Our people search service utilizes public information like criminal records, marriage records, civil records, bankruptcies, liens, lawsuits, etc. All of this information powers our free people search service so that our clients can find anyone quick and easy. You’ll never have to wonder about the one who got away ever again. Are you looking for a lost love or family member? Are you searching for somebody who might have fallen through the cracks? We can help.`
              "
            />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
// Components
import TzBanner from '@trazi/tz-banner/src/tz-banner.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
// Images
import longArrowImg from '@/assets/images/icons/long-arrow.svg';
import securityBadges from '@/assets/images/logos/security-badges.webp';
import asSeenOnImg from '@/assets/images/logos/as-seen-on.png';
import AccuratePeopleLookImg from '@/assets/images/home/accurate-people-lookup.svg';
import getRegex from '@/assets/js/shared/helpers/getRegex';
// Misc
import Meta from '@/assets/js/shared/misc/meta.mjs';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import formatCity from '@/assets/js/shared/helpers/formatCity';

export default {
  components: { TzBanner, TzSearch },
  data() {
    return {
      longArrowImg,
      securityBadges,
      asSeenOnImg,
      AccuratePeopleLookImg,
      fallbackFields: [{ aid: 11 }, {}],
      peopleURL: `${this.$config.baseUrl}/searching`,
      searchTabs: [
        {
          name: 'People',
          link: '/'
        }
      ],
      textSearchIconSize: { height: '13', width: '13' },
      getRegex
    };
  },
  methods: {
    handleSearch(event) {
      const { firstName, lastName, city, state } = event;
      const searchQuery = {
        firstName: setTitleCase({ text: firstName }),
        lastName: setTitleCase({ text: lastName }),
        city: formatCity({ city }),
        state
      };
      const searchLink = this.$router.resolve({
        name: 'landing.searching',
        params: { landingID: 'c0d1' },
        query: {
          ...this.$route.query,
          time: Date.now(),
          ...searchQuery
        }
      }).href;
      window.location = searchLink;
    },
    scrollToTop() {
      scroll({
        top: 0,
        behavior: 'smooth'
      });
      // This is to prevent the page from jumping when focusing on the input field
      setTimeout(() => {
        document.querySelector('.tz-search__input-field').focus();
      }, 900);
    }
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: require(`@/assets/cms/pages/home.json`),
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
