var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fp-homepage"},[_c('tz-banner',{attrs:{"title":"Free People Search Service","description":"Find Email Addresses, Phone Numbers, Social Media Data, Contact Information and More!","has-overlay":false}},[_c('div',{staticClass:"fp-homepage__banner-search-tabs"},_vm._l((_vm.searchTabs),function(tab){return _c('a',{staticClass:"fp-homepage__banner-search-tab",class:{
          'fp-homepage__banner-search-tab--active': tab.name === 'People'
        },attrs:{"href":tab.link},domProps:{"innerHTML":_vm._s(`${tab.name} <span>Search</span>`)}})}),0),_vm._v(" "),_c('tz-search',{attrs:{"people-link":_vm.peopleURL,"prefill-fields":_vm.$parent.$attrs['prefill-fields'],"fallback-fields":_vm.fallbackFields,"search-icon":_vm.longArrowImg,"text-search-icon-size":_vm.textSearchIconSize,"people-invalid-regex":_vm.getRegex({ regexType: 'peopleInvalidRegex' }),"no-query-string":true},on:{"tz-search-override-redirect":_vm.handleSearch}}),_vm._v(" "),_c('div',{staticClass:"fp-homepage__banner-secured"},[_c('img',{attrs:{"src":_vm.securityBadges,"alt":"","width":"220","height":"30"}}),_vm._v(" "),_c('p',{staticClass:"fp-homepage__banner-secured-text",domProps:{"textContent":_vm._s(`This connection is secured`)}})])],1),_vm._v(" "),_c('section',{staticClass:"fp-homepage__section fp-homepage__section--seen-on"},[_c('img',{attrs:{"src":_vm.asSeenOnImg,"alt":"Company logos","width":"350","height":"30"}})]),_vm._v(" "),_c('section',{staticClass:"fp-homepage__section"},[_c('div',{staticClass:"fp-homepage__row"},[_c('img',{staticClass:"fp-homepage__section-image",attrs:{"src":_vm.AccuratePeopleLookImg,"alt":"Accurate People Lookup Online"}}),_vm._v(" "),_c('div',[_c('h2',{domProps:{"textContent":_vm._s(`Accurate People Lookup Online`)}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
            `Need to find someone and don’t know where to start? That’s okay! Free People Search makes it fast, easy,
          and effective to perform an advanced people search today. People lookup services can be flaky and seldom do
          they truly deliver the goods. But we can help you find anyone with our free people search tool.`
          )}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
            `Do you need to perform a people search to make contact, or do you
          need to obtain a criminal background check? We can do it all at
          Free People Search, where your people lookup troubles will go away
          right when you use our website. We make the process easy and
          informative.`
          )}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
            `Our people search service utilizes public information like criminal
          records, marriage records, civil records, bankruptcies, liens,
          lawsuits, etc. All of this information powers our free people search
          service so that our clients can find anyone quick and easy.`
          )}})])])]),_vm._v(" "),_c('section',{staticClass:"fp-homepage__section fp-homepage__section--color"},[_c('ul',{staticClass:"fp-homepage__list fp-homepage__list--decisions"},[_c('li',{domProps:{"textContent":_vm._s(`The best tool to Search People`)}}),_vm._v(" "),_c('li',{domProps:{"textContent":_vm._s(`100% Confidential Searches`)}}),_vm._v(" "),_c('li',{domProps:{"textContent":_vm._s(`Peace of mind, Always!`)}}),_vm._v(" "),_c('li',{domProps:{"textContent":_vm._s(`Easy to Use and Understand`)}})]),_vm._v(" "),_c('a',{staticClass:"fp-homepage__button",attrs:{"href":"#"},domProps:{"textContent":_vm._s(`Make A Search`)},on:{"click":function($event){$event.preventDefault();return _vm.scrollToTop.apply(null, arguments)}}})]),_vm._v(" "),_c('section',{staticClass:"fp-homepage__section"},[_c('div',{staticClass:"fp-homepage__container"},[_c('h2',{domProps:{"textContent":_vm._s(`Frequently Asked Questions`)}}),_vm._v(" "),_c('ul',{staticClass:"fp-homepage__list fp-homepage__list--faq"},[_c('li',[_c('h3',{domProps:{"textContent":_vm._s(`Why run a People Lookup Search with Free People Search?`)}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
              `We provide instantaneous access to abundant contact information, background checks, public records, and more, all in one, easy-to-read printout report. Find people, contacts, and learn more about criminal records so that you can connect with others with a new peace of mind while protecting your own personal safety.`
            )}})]),_vm._v(" "),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(`Is there a limit on how many people I can search?`)}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
              `With FreePeopleSearch, you can get unlimited searches & reports! Our people search services are never meant to be used to stalk or harass individuals, or to steal another person’s identity. We are a safe and easy people search solution when other background check and people lookup services turn up dry.`
            )}})]),_vm._v(" "),_c('li',[_c('h3',{domProps:{"textContent":_vm._s(
              `What type of information will I find with Free People Search?`
            )}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(
              `Our people search service utilizes public information like criminal records, marriage records, civil records, bankruptcies, liens, lawsuits, etc. All of this information powers our free people search service so that our clients can find anyone quick and easy. You’ll never have to wonder about the one who got away ever again. Are you looking for a lost love or family member? Are you searching for somebody who might have fallen through the cracks? We can help.`
            )}})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }